ul {
    list-style: none;
    list-style-type: none !important;
}

input[type='checkbox'] {
    width: unset !important;
    height: unset !important;
    display: unset !important;
}
